<template>
    <teleport v-if="mountedComponent" to="#settings-header">
        <h1 class="settings-list__heading">All Settings</h1>
    </teleport>

    <teleport v-if="mountedComponent" to="#header-nav">
        <CBreadcrumbItem active>Settings</CBreadcrumbItem>
    </teleport>

    <div class="all-settings">
        <div v-for="section in filteredSettingsSections" :key="section.title" class="all-settings__section">
            <span class="all-settings__section-title">{{ section.title }}</span>
            <div v-for="(item, section) in section.items" :key="item.label" class="all-settings__section-item">
                <span class="all-settings__section-link" @click="onSettingClick(item)">{{ item.label }}</span>
                <p class="all-settings__section-text">{{ item.text }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Gate from '@/services/Gate/Gate'
import {hasRoutePermission} from '@/utils/helper'
import store from "@/store";
import {notifyByPlan, permissionError} from "@/services/Notify/Toasts";

export default {
    name: 'SettingList',
    data() {
        return {
            mountedComponent: false,

            settingsSections: [
                {
                    title: 'My Company',
                    items: [
                        {
                            label: 'My Profile',
                            routeName: 'MyProfile',
                            text: 'Login and contact information, permissions notifications and written signatures'
                        },
                        {
                            label: 'Account Details',
                            routeName: 'SettingAccountDetails',
                            text: 'Information about your account, account and payment plan, default account email, close account',
                            guard: async () => {
                                const check = await Gate.can('accountDetails', 'setting')
                                if (!check) store.commit('setToast', permissionError)
                                return check
                            },
                        },
                        {
                            label: 'Company Details',
                            routeName: 'SettingCompanyDetails',
                            text: 'Information about your company, tax ID, certifications, licenses, subsidiary companies, office locations',
                            guard: async () => {
                                const check = await Gate.can('companyDetails', 'setting')
                                if (!check) store.commit('setToast', permissionError)
                                return check
                            },
                        },
                        {
                            label: 'Employees & Invites',
                            routeName: 'SettingCompanyUsersIndex',
                            text: 'List of employees, employees’ roles and invite status, number of projects and tasks'
                        },
                        {
                            label: 'Permission Groups',
                            routeName: 'SettingPermissionGroups',
                            text: 'User roles for your company, list of features and capabilities per type of user',
                            guard: async () => {
                                const check = await Gate.can('view', 'setting')
                                if (!check) store.commit('setToast', permissionError)
                                return check
                            }
                        }
                    ]
                },
                {
                    title: 'Templates',
                    items: [
                        {
                            label: 'Merge Fields',
                            routeName: 'SettingDocumentTemplate',
                            text: 'Manage default file naming, all merge fields, and export all merge fields',
                            guard: async () => {
                                const check = await Gate.can('templatesView', 'setting')
                                if (!check) notifyByPlan()
                                return check
                            },
                        },
                        {
                            label: 'Email Templates',
                            routeName: 'SettingEmailTemplates',
                            text: 'Manage Email Templates',
                            guard: async () => {
                                const check = await Gate.can('templatesView', 'setting')
                                if (!check) notifyByPlan()
                                return check
                            },
                        }
                    ]
                },
                {
                    title: 'Projects',
                    items: [
                        {
                            label: 'Contract Approvals',
                            routeName: 'SettingContractApprovals',
                            text: 'Create internal workflows for contract approvals, negotiations and signing',
                            guard: async () => {
                                const check = await Gate.can('projectView', 'setting')
                                if (!check) notifyByPlan()
                                return check
                            },
                        },
                        {
                            label: 'Scope Approvals',
                            routeName: 'SettingScopeApproval',
                            text: 'Create internal workflows for scope of work reviews, approvals, and limits on purchasing',
                            guard: async () => {
                                const check = await Gate.can('projectView', 'setting')
                                if (!check) notifyByPlan()
                                return check
                            },
                        },
                        {
                            label: 'Company Types',
                            routeName: 'SettingCompanyTypes',
                            text: 'Apply company types to various vendors in your network',
                            guard: async () => {
                                const check = await Gate.can('edit', 'setting')
                                if (!check) notifyByPlan()
                                return check
                            },
                        }
                    ]
                }
            ]
        }
    },
    computed: {
        ...mapGetters([
            'user',
            'isGeneralPlan'
        ]),
        filteredSettingsSections() {
            if (this.isGeneralPlan) {
                return this.settingsSections;
            }

            return this.settingsSections.filter(section => section.title === 'My Company');
        }
    },
    mounted() {
        this.mountedComponent = true
    },
    methods: {
        async onSettingClick(setting) {
            let params = {}

            if (setting.label === 'My Profile') {
                params = {id: this.user.id}
            }

            let check = true
            if (setting.guard && typeof setting.guard === 'function') {
                check = await setting.guard()
            }

            if (check && this.$router.hasRoute(setting.routeName)) {
                this.$router.push({name: setting.routeName, params})
            }

            return false
        },
        checkPermission(route) {
            if (hasRoutePermission(route)) {
                return route.checkType === 'any'
                    ? this.authUser.canAny(route.permissions)
                    : this.authUser.canAll(route.permissions)
            } else {
                return true
            }
        },
    },
}
</script>
<style lang="scss">

.settings-list {
    &__heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #1c262f;
    }
}

.all-settings {
    display: flex;
    gap: 37px;
    background: white;
    border-radius: 8px;
    padding: 48px;

    &__section {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 307px;
        flex-shrink: 0;

        &-title {
            color: #1C262F;
            font-weight: 600;
            font-size: 20px;
            display: block;
            margin-bottom: 8px;
        }

        &-text {
            color: #677A89;
            font-size: 14px;
            margin-top: 8px;
        }

        &-item {
            min-height: 115px;
        }

        &-link {
            color: #0068AD;
            font-size: 16px;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
</style>
